import React from 'react'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AutoModeRoundedIcon from '@mui/icons-material/AutoModeRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import HowToRegIcon from '@mui/icons-material/HowToReg';
// import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import BackupTableRoundedIcon from '@mui/icons-material/BackupTableRounded';
import { Drawer, DrawerHeader, DrawerListItem } from './components'
import {useKeycloak} from "@react-keycloak/web";
import {paths} from "../../constants/paths";
import {useNavigate} from "react-router-dom";
import {scrollToElementById} from "../../helpers/helpers";
import {SECTION_ID} from "../Landing/mappings";
import {useScroll} from "../../hooks/useScroll";
import useActiveSections from "../../hooks/useActiveSections";
import { COMPANIES } from '../../hooks/useCompany'
import { REDIRECT_ADMIN } from '../../constants'
import { useSelector } from 'react-redux'
import { selectTenantConfig } from '../../store/reducers/tenant.reducer'

export const Sidebar = ({ isOpen, handleClose }) => {
    const { keycloak } = useKeycloak()
    const navigate = useNavigate()
    const { scrollY } = useScroll()
    const { activeSectionId } = useActiveSections(scrollY)
    const tenantConfig = useSelector(selectTenantConfig)

    const handleLogin = () => keycloak.login({
        redirectUri: `${window.location.origin}/${paths.form()}`
    })
    // const handleRegister = () => keycloak.register({
    //     redirectUri: `${window.location.origin}/${paths.form()}`
    // })
    const handleLogout = () => keycloak.logout()

    const navigateToOnboardingForm = () => navigate(paths.form())

    // const navigateToMerchantDirectory = () => navigate(paths.merchantDirectory())

    const handleRedirect = () => window.location.replace(tenantConfig.domain || REDIRECT_ADMIN)

    const handleRegister = () => keycloak.register({
      redirectUri: `${window.location.origin}/${paths.form()}`
    })

    // const hideMerchantDirectory = tenantConfig.companyName === COMPANIES.THE_BOX_WALLET
    const hideOnboarding = tenantConfig.companyName !== COMPANIES.ZERO_LEDGER


    const HomeItem = () => (
        <DrawerListItem
            onClick={() => window.scrollTo({ behavior: 'smooth', top: 0 } )}
            Icon={HomeRoundedIcon}
            label="Home"
            isActive={activeSectionId === SECTION_ID.HOME}
        />
    )

    const RegisterWalletItem = () => !hideOnboarding && (
      <DrawerListItem
        onClick={() => navigate(paths.registerWallet())}
        Icon={HowToRegIcon}
        label="Create wallet"
      />
    )

    const LoginWalletItem = () => !hideOnboarding && (
      <DrawerListItem
        onClick={() => navigate(paths.loginWallet())}
        Icon={LoginRoundedIcon}
        label="Login"
      />
    )

    const SolutionsItem = () => (
        <DrawerListItem
            onClick={() => scrollToElementById(SECTION_ID.SOLUTIONS)}
            Icon={AutoModeRoundedIcon}
            label="Solutions"
            isActive={activeSectionId === SECTION_ID.SOLUTIONS}
        />
    )

    const AboutItem = () => (
        <DrawerListItem
            onClick={() => scrollToElementById(SECTION_ID.ABOUT)}
            Icon={InfoRoundedIcon}
            label="About Us"
            isActive={activeSectionId === SECTION_ID.ABOUT}
        />
    )

    const ContactItem = () => (
        <DrawerListItem
            onClick={() => scrollToElementById(SECTION_ID.CONTACT)}
            Icon={CallRoundedIcon}
            label="Contact"
            isActive={activeSectionId === SECTION_ID.CONTACT}
        />
    )

    const AdminItem = () => !hideOnboarding && (
        <DrawerListItem
            onClick={handleRedirect}
            Icon={InfoRoundedIcon}
            label="Admin"
        />
    )

    // const MerchantDirectoryItem = () => !hideMerchantDirectory && (
    //   <DrawerListItem
    //     onClick={navigateToMerchantDirectory}
    //     Icon={InfoRoundedIcon}
    //     label="Merchant Directory"
    //     isActive={activeSectionId === SECTION_ID.MERCHANT_DIRECTORY}
    //   />
    // )


    const OnboardingFormItem = () => keycloak.authenticated && (
        <DrawerListItem
            onClick={navigateToOnboardingForm}
            Icon={BackupTableRoundedIcon}
            label="Onboarding Form"
        />
    )

    const LogoutItem = () => keycloak.authenticated && (
        <DrawerListItem
            onClick={handleLogout}
            Icon={LogoutRoundedIcon}
            label="Logout"
        />
    )

    const LoginItem = () => !keycloak.authenticated && !hideOnboarding && (
        <DrawerListItem
            onClick={handleLogin}
            Icon={LoginRoundedIcon}
            label="Login"
        />
    )

  const StartOnboardingItem = () => !keycloak.authenticated && hideOnboarding && (
        <DrawerListItem
            onClick={handleRegister}
            Icon={LoginRoundedIcon}
            label="Start Onboarding"
        />
    )



    // const RegisterItem = () => !keycloak.authenticated && !hideOnboarding && (
    //     <DrawerListItem
    //         onClick={handleRegister}
    //         Icon={PersonAddAltRoundedIcon}
    //         label="Start Onboarding"
    //     />
    // )

    return (
        <Drawer
            isOpen={isOpen}
            onDrawerClose={handleClose}
            drawerHeader={<DrawerHeader isOpen={isOpen} handleCloseSidebar={handleClose} />}
            drawerItems={[
                HomeItem,
                SolutionsItem,
                AboutItem,
                RegisterWalletItem,
                LoginWalletItem,
                ContactItem,
                AdminItem,
                // MerchantDirectoryItem,
                StartOnboardingItem,
                OnboardingFormItem,
                LogoutItem,
                LoginItem,
                // RegisterItem,
            ].map((item, id) => ({ DrawerItem: item, id }))}
        />
    )
}
